
@import "~@/css/variables";

.base-input {
    --base-input-height: 40px;
    --base-input-border-width: 0;
    --base-input-icon-size: 18px;
    --base-input-font-size: var(--f7-font-size);

    // icon 置中用
    --half-base-input-height: calc(var(--base-input-height) / 2);
    --half-base-input-icon-size: calc(var(--base-input-icon-size) / 2);
    --half-base-input-border-width: calc(var(--base-input-border-width) / 2);
    --half-icon-size-and-font-size-diff: calc( ( var(--base-input-icon-size) - var(--base-input-font-size) ) / 2 * 1.5);
    --icon-center-mt: calc( var(--half-base-input-height) - var(--half-base-input-border-width) - var(--half-base-input-icon-size) - var(--half-icon-size-and-font-size-diff) );
    // icon 置中用 /

    margin-bottom: 20px;
    font-size: var(--f7-font-size);

    .item-input.item-input-focused .item-input-wrap:after{
        display: none;
    }

    input, select, textarea{
        max-width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        border: 0;
        font-size: var(--base-input-font-size);
    }
    input, select{
        height: calc(var(--base-input-height) - (var(--base-input-border-width) * 2) );
    }
    input, textarea{
        &::placeholder{
            color: #898989;
        }
    }
    textarea{
        padding-top: 15px;
        padding-bottom: 15px;
    }
    // 子項目
    .base-input{
        margin-bottom: 0;
    }
    // 子項目 /

    .item-content {
        padding: 0;
    }

    .item-title{
        overflow: visible;
    }

    .item-label{
        font-size: 1em;
        margin-bottom: 10px;
        font-weight: bold;
        .label-txt{
            display: inline-block;
            position: relative;
            color: $color-font;
        }
    }

    .item-inner {
        padding: 0;
        display: block;
        &::after {
            display: none;
        }
    }

    .item-input-wrap{
        // input select textarea 位置
        flex-grow: 1;
        max-width: 100%;
        min-width: 0;
        min-height: auto;
        &:after{
            display:none!important;
        }
    }

    // password
    &.sty-password{
        .password-tools{
            font-size: 18px;
            line-height: 1;
            margin-top: var(--icon-center-mt);
            margin-left: 10px;
            color: #898989;
        }
    }

    // uderline
    &.sty-underline{

        input, select {
            height: 39px;
            padding: 0;
        }
        .item-title{
            margin-bottom: 0;
            line-height: 1.2;
        }
        .item-content{
            border-bottom: 1px solid #DDDDDD;
        }
        .item-input-wrap {
            margin-top: 0!important;
            margin-bottom: 0 !important;
            display: flex;
            position: relative;
            box-sizing: border-box;
            transition: border .3s;
            .icon {
                font-size: 20px;
                color: #A8A8AB;
                margin-top: 12px;
                margin-right: 10px;
                transition: .3s;
            }

            // icon 特別調整
            .icon-phone-call{
                font-size: 17px;
            }
        }

        // 其他
        .btn-get-code{
            margin-top: 4px;
            width:100px;
            font-size: 14px;
            flex-shrink: 0;
            height: 30px;
        }
    }
    // uderline /

    // shadow bar
    &.sty-shadowbar{

        &:focus-within{

        }

        .item-input-wrap {
            margin-top: 0!important;
            margin-bottom: 0 !important;
            border: 1px solid #fff;
            display: flex;
            background: #fff;
            border-radius: 10px;
            padding: 0 10px;
            position: relative;
            box-sizing: border-box;
            box-shadow: 0 3px 10px #3333331A;
            transition: border .3s;
            .icon {
                font-size: 20px;
                color: #A8A8AB;
                margin-top: 12px;
                margin-right: 10px;
                transition: .3s;
            }

            // icon 特別調整
            .icon-phone-call{
                font-size: 17px;
            }
        }
    }
    // shadow bar /

    // shadow bar
    &.sty-bar{
        --base-input-border-width: 3px;

        &:focus-within, &:active{
            .item-input-wrap{
                border-color: $color-sec;
            }
        }

        .item-input-wrap {
            margin-top: 0!important;
            margin-bottom: 0 !important;
            border:var(--base-input-border-width) solid #EEF5CC;
            display: flex;
            background: #fff;
            border-radius: 10px;
            padding: 0 15px;
            position: relative;
            box-sizing: border-box;
            transition: border .3s;
            .icon {
                font-size: var( --base-input-icon-size);
                color: $color-primary;
                margin-top: var(--icon-center-mt);
                margin-right: 10px;
                transition: .3s;
            }

            // icon 特別調整
            .icon-phone-call{
                --base-input-icon-size: 17px;
            }
            .icon-mail1{
                font-size: 13px;
                transform: translateY(0.2em);
            }
        }
    }
    // shadow bar /

    // see-through
    &.sty-see-through{
        .item-input-wrap {
            display: flex;
            margin-bottom: 0;
            margin-top: 0;
            .icon {
                font-size: var( --base-input-icon-size);
                color: $color-primary;
                transition: .3s;
                margin-left: 10px;
            }

            // icon 特別調整
            .icon-phone-call{
                font-size: 17px;
            }
            .icon-mail1{
                font-size: 13px;
            }
        }
    }
    // see-through /

    // custom-controls
    &.sty-custom-controls{
        .item-title{
            margin-bottom: 10px;
        }
        .item-input-wrap-outer{
            display: none;
        }
        .base-radio{
            margin-bottom: 10px;
            font-size: 14px;
        }
    }

    .custom-controls-wrap{
        width: 100%;
    }
     // custom-controls /

    // select
    &.sty-select{
        .item-input-wrap-outer{
            position: relative;
        }
        .item-input-wrap{
            background: #EEF5CC;
            border-radius: 5px;
        }
        .select-icon{
            position: absolute;
            font-size: 16px;
            right: 20px;
            top: 50%;
            font-size: 12px;
            transform: translateY(-50%) scale(calc(6 / 12));
            font-weight: bold;
            color: $color-primary;
        }
        input{
            color:$color-font;
            &::placeholder{
                color: $color-font;
            }
        }
    }
    // select /

    // address
    &.sty-address{
        .address-content{
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 8px 0;
            padding-right: 10px;
            .content-main{
                flex-grow: 1;
                padding-right: 1em;
            }
            .content-append{
                flex-shrink: 0;
            }
            .address-name{
                color: #008E4B;
                margin-bottom: 3px;
            }
            .address-detailed{
                color: #231815;
            }
            i {
                color: #CDC990;
                font-size: 12px;
            }
        }
    }
    // address /

    // readonly
    &.base-input_readonly{
        &[class*="sty-"]:not(.sty-see-through){
            .item-input-wrap{
              background: #fafafa;
            }
        }
    }
    // readonly /

    .item-input-wrap-outer{
        display: flex;
        width: 100%;
    }

    // 星星
    .star-before, .star-after{
        position:absolute;
        color: #FF7589;
        top: 0;
    }
    .star-before{
        right: 100%;
    }
    .star-after{
        left: 100%;
    }
    // 星星 /

}

.error-msg {
    padding-top: 5px;
    display: inline-block;
    color: $color-danger;
    font-size: 1em;
}
