
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

// muki-barcode
.muki-barcode{
    font-size: 1rem;
    img {
        display: block;
        margin: 0 auto;
    }
    .barcode-content{
        text-align: center;
        padding-top: 10px;
        font-size: 1em;
        font-weight: bold;
    }
    .center-info {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
// muki-barcode /

