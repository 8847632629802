
@import "~@design";

// img-fallback
.img-fallback{
  background-size: 155% 100%;
  background:#dedede80;
  img, svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 80%;
    max-height: 80%;
  }

  // 預設正方形
  &_default{
    width: 100%;
    &:before{
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  &_abs-fill{
    @include absolute(0,0,0,0);
  }
}
// img-fallback /
