
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

 // countdown-gadge
.countdown-gadge{
    --countdown-gadge-colon-space: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(12 / 14 * 1rem);
    color: $color-font;
    &__title{
        margin-right: 8px;
        letter-spacing: 1.4px;
        font-size: calc( 14 / 12 * 1em);
        height: calc( 20 / 12 * 1em);
        font-family: $ff-msjh;
        background: #231815;
        color: #fff;
        border-radius: 3px;
        padding: 0px 3px;
    }
    &__main{
        display: flex;
    }
    &__box{
        @include flex(center,center);
        &:not(&_narrow){
            width: calc( 20 / 12 * 1em);
            height: calc( 20 / 12 * 1em);;
            background: #231815;
            color: #fff;
            border-radius: 3px;
        }
        &_narrow{
            margin: 0 var(--countdown-gadge-colon-space);
        }

        &_3digi{
            min-width: 2.4em;
        }
    }


    &_has-3digi{
        --countdown-gadge-colon-space: 4px;
    }

    @include media(414){
        &_small-countdown{
            .countdown-gadge__title{
                font-size: 12px;
                margin-right: 5px;
            }
            .countdown-gadge__box_narrow{
                --countdown-gadge-colon-space: 2px;
            }
        }
    }

}
// countdown-gadge /

