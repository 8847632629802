
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.loading-wrap {
    position: fixed;
    z-index: 10000000;
    background: rgba(0, 0 ,0 , 0.7);
    height: 100vh;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loading-wrap svg{
    transform: scale(1.5);
}
.loading-wrap svg path,
.loading-wrap svg rect{
    fill: #ABCD03;
}

.loading-wrap_text{
    color: #ABCD03;
    margin-top: 10px;
    letter-spacing: 1px;
}

