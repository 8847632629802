
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.btn-envelope {
    position: relative;
    span{
        font-weight: lighter;
    }
    img {
        width: 1.875rem;
    }
}

