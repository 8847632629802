
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.muki-price-displayer{
    display: inline-block;
    font-size: calc( 18 / 14 * 1rem);
    line-height: 1;
    font-weight: bold;
    color: $color-font;
    .displayer-inner{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: -7px;
    }

    img{
        margin-right: 6px;
        max-width: calc( 20 / 18 * 1em);
    }
    .displayer-plus{
        margin: 0 5px;
        margin-bottom: 7px;
    }
    .displayer-item{
        display: flex;
        align-items: center;
        margin-bottom: 7px;
    }
}


