
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.muki-num-badge {
    --muki-num-badge-bg: #{$color-sec};
    --muki-num-badge-size: 18px;
    min-width: auto;
    width: var(--muki-num-badge-size);
    height: var(--muki-num-badge-size);
    padding: 0;
    border-radius: 50%;
    font-family: $ff-nunito;
    font-size: 12px;
    font-weight: 100;
    
    box-sizing: border-box;
    background-color: var(--muki-num-badge-bg);
    line-height: 1;

    &_abs-position{
        position: absolute;
        top: -10px;
        right: -10px;
    }

    &.is-over-99 {
        font-size: 12px;
        width: auto;
        right: -0.8125rem;
        padding: 0 1px;
        border-radius: var(--f7-badge-size);
    }

    &.sty-bold{
        font-weight: bold;
        span{
            font-weight: bold;
        }
    }
}

