
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.fav-toggle{
    --not-active-color: #fff;
    --active-color: #df4710;
    @include flex(center,center);
    display: inline-flex;

    .toggle-active{
        color: var(--active-color);
    }

    .toggle-not-active{
        color:var(--not-active-color);
    }
}

