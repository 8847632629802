
@import "~@design";

.p-index{
    .page-content{
        overflow-x:hidden;
    }
}

.swiper-overlay{
    display: block;
    width: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzc1IiBoZWlnaHQ9IjYzLjMzMyIgdmlld0JveD0iMCAwIDM3NSA2My4zMzMiPjxkZWZzPjxzdHlsZT4uYSwuYywuZHtmaWxsOiNmZmY7fS5ie29wYWNpdHk6MC44O2NsaXAtcGF0aDp1cmwoI2EpO30uY3tvcGFjaXR5OjAuMzt9LmR7b3BhY2l0eTowLjU7fTwvc3R5bGU+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBjbGFzcz0iYSIgZD0iTTQ0NSwzMDk3LjkxM3MxNDMuMjUsMy4yLDIzNywwLDEzOC0xMi43ODYsMTM4LTEyLjc4NlYzMDM2SDQ0NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTI3MTkpIi8+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xhc3M9ImIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMxNykiPjxwYXRoIGNsYXNzPSJjIiBkPSJNODE5LDMzMTNjLTEzOC0xMjYtMzc0LDAtMzc0LDB2NTJIODE5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0NSAtMjk0MCkiLz48cGF0aCBjbGFzcz0iZCIgZD0iTTYzOSwzMzM0Yy01OC00OS0xOTQtNjAtMTk0LTYwdjkySDYzOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTI5NDApIi8+PC9nPjwvc3ZnPg==);
    background-position: center bottom;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 10%;
    z-index: 10;
    padding-top: 16.804%;
}


// Swiper Banner
.skeleton-block {
    position:relative;
    padding-top: calc(530 / 1450 * 100%);
    overflow: hidden;
    .swiper-container{
        @include absolute(0,0,0,0);
    }
    ~ .wav{
        height: 32px;
        min-height: 32px;
        background: url('@/assets/images/general/wave.png');
        background-repeat: repeat-x;
        background-size: auto 100%;
        width: 100%;
        margin-top: -10px;
        position: relative;
        z-index: 1;
    }
}

// member-info-bed
.member-info-bed{
    .row{
        align-items: center;
    }
    .col-right{
        text-align: right;
    }
}
// member-info-bed /

// points-displayer
.points-displayer{
    font-size: 18px;
    font-weight: bold;
    font-family: $ff-nunito;
    vertical-align: middle;
}
// points-displayer /

#swiper-index {
    &::after {
        content: "";
        display: block;
        width: 100%;
        padding-top: 16.804%;
        // background-image: url("");
        background-position: center bottom;
        background-size: cover;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
    }

    // pagination
    .swiper-pagination {
        bottom: 0px;
        z-index: 100;
    }

    .swiper-pagination-bullet {
        margin: 0 2.5px;
        width: 8px;
        width: 8px;
        opacity: 0.7;
        &.swiper-pagination-bullet-active{
            background: $color-sec;
        }
    }

    .swiper-slide {
        @include size(100%, 100%);
        position: relative;
        overflow: hidden;
    }

    .link {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    img {
        max-width: initial;
        height: 100%;
        @include pos-center;
    }

    .swiper-text {
        display: flex;
        font-family: $ff-open-sans;
        font-size: $fz-h6;
        letter-spacing: 0.0313rem;
        position: absolute;
        left: 1.875rem;
        bottom: 3.75rem;
        color: $color-pure;

        &::before {
            content: "";
            display: inline-block;
            @include size(1.0625rem, 1.4375rem);
            margin-right: 0.625rem;
            margin-top: 0.3125rem;
            // background-image: url("");
            background-position: center center;
            background-size: cover;
        }
    }
}

// Feature Button
#feature-menu-block {
    padding-top: 10px;
    padding-bottom: 5px;
    @include mobile-down{
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0;
    }
}

.btn-feature {
    display: flex;
    flex-direction: column;
    margin:10px 0;
    margin-left: auto;
    margin-right: auto;
    pointer-events: visible;
    font-size: 1rem;
    text-align: center;

    @include media-up(630){
        font-size: 1.1rem;
    }

    .btn-icon{
        width: 80%;
        max-width: 90px;
        position: relative;
        margin-bottom: 0.357em;
        border-radius: 50%;
        overflow: hidden;
        &:before{
            content:'';
            display: block;
            padding-top: 100%;
        }
        i, img {
            position:absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: 1.875em;
        }
    }
    .img-wrap{
        position:relative;
    }
    .unread-badge{
        position: absolute;
        left: 0;
        top: 0;
        background:$color-danger;
    }
    .feature-text{
        color:#231815;
        margin: 0;
        font-weight: bold;
    }

    &.active-state {

    }
    &.sty-green{
        .btn-icon{
            background: #E1FFD9;
            i {
                color: #62c773;
            }
        }
    }
    &.sty-red{
        .btn-icon{
            background: #FFDEDC;
            i {
                color: #ff726a;
            }
        }
    }
    &.sty-purple{
        .btn-icon{
            background: #E1E9FC;
            i {
                color: #728fff;
            }
        }
    }
    &.sty-sea{
        .btn-icon{
            background:#BEEDFF;
            i {
                color: #00b8ff;
            }
        }
    }
    &.sty-orange{
        .btn-icon{
            background: #FFEEC7;
            i {
                color: #ffa467;
            }
        }
    }
    &.sty-cryan{
        .btn-icon{
            background: #BEF6E9;
            i {
                color: #2db192;
            }
        }
    }
    &.sty-blue{
        .btn-icon{
            background: #E5F4FD;
            i {
                color: #4ca8ea;
            }
        }
    }
    &.sty-yellow{
        .btn-icon{
            background: #FDF3CC;
            i {
                color: #f5cc48;
            }
        }
    }
}

@include mobile-down{
    .btn-feature {
        .feature-text{
            font-size: 13px;
        }
    }
}


.media-block{
    margin-bottom: 5px;
    --f7-grid-gap: 10px;
    img{
        display: block;
    }
    [class*="col"]{
        margin-bottom:10px;
    }
    .media-block-item{
        position: relative;
        display: block;
        &.side-item{
            margin-bottom: 9px;
        }

        &.free-mode{

        }

        &.ratio-390by185{
            &:before{
                content:'';
                display: block;
                padding-top: calc(185 / 390 * 100%);
            }
        }

        &.ratio-768by290{
            &:before{
                content:'';
                display: block;
                padding-top: calc(290 / 768 * 100%);
            }
        }

        &.ratio-655by470{
            &:before{
                content:'';
                display: block;
                padding-top: calc(470 / 655 * 100%);
            }
        }

        &.ratio-auto{
            img{
                position: relative;
            }
        }

        &.ratio-1by1{
            &:before{
                content:'';
                display: block;
                padding-top: 100%;
            }
        }

        img{
            @include absolute(0,0,0,0);
            margin: auto;
            max-height: 100%;
        }
    }

    &_3count{
        .media-block-item:last-child{
            margin-bottom: 0;
        }
        .media-block-item{
            img{
                position: relative;
            }
        }
    }
}

