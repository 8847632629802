
@import "~@design";

.product-box-block{
    .row{
        align-items: stretch;
        > [class*="col"]{
            --f7-grid-gap: 10px;
            margin-bottom: 1.07em;
            flex-direction: column;
            display: flex;
        }
    }
    .product-box{
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .box-body{
            flex-grow: 1;
        }
    }
}

.product-box{
    font-size: 1rem;
    margin-bottom: 1.07em;
    // filter: drop-shadow(5px 5px 15px rgba(#000, 0.16));
    display: flex;
    flex-direction: column;
    
    &.sty-padding-small{
        .box-body{
            padding: 5px;
        }
        .box-fav-btn{
            top: 5px;
            right: 5px;
        }
        .box-infobar{
            font-size: calc(12 / 14 * 1rem);
        }
    }
    .box-media{
        position: relative;
        &:before{
            content:'';
            display: block;
            padding-top: 100%;
        }
        img{
            @include absolute(0,0,0,0);
            max-height: 100%;
            margin: auto;
            border-radius: 10px;
        }
        .box-status-overlay{
            width: 45%;
            @include flex(center,center);
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            background: rgba(#000000, 0.5);
            letter-spacing: 0.1em;
            transform: translateX(-50%) translateY(-50%) translateY(-15px);
            color: #FF0000;
            font-weight: bold;
            border: 2px solid #FF0000;
            text-shadow: 0 0 5px #fff;
            font-size: 16px;
            &:before{
                content:'';
                display: block;
                padding-top: 100%;
            }
        }
    }
    .muki-price-displayer{
        font-size: calc(16 / 14 * 1rem);
    }
    .box-media-inner{
        background: #fff;
        @include absolute(0,0,0,0);
        border-radius: 10px 10px 0 0;
        overflow:hidden;
        border: 2px solid #EEF5CC;
        border-bottom: 0;
    }
    .box-body{
        flex-grow: 1;
        border-radius: 0 0 10px 10px;
        border: 2px solid #EEF5CC;
        border-top: 0;
        padding:10px;
        background:#EEF5CC;
        display: flex;
        flex-direction: column;
        .box-title{
            font-size: 1em;
            line-height: 1.357;
            font-weight: bold;
            @include text-lines-ellipsis(1.357em, 2);
            max-height: 1.357em * 2;
            flex-grow: 1;
            color: $color-font;
            margin-bottom: 3px;
        }
    }
    .box-countdonw-bar{
        position: absolute;
        bottom:0;
        left: 0;
        right: 0;
        background:rgba(#DF4710, 0.3);
        padding: 5px;
        border-radius: 10px 10px 0 0;
    }
    .box-fav-btn{
        position: absolute;
        top:10px;
        right: 10px;
        width: calc(26 / 14 * 1em);
        height: calc(26 / 14 * 1em);
        border-radius: 50%;
        background: rgba(#000000, 0.2);
        @include flex(center,center);
    }
    .box-infobar{
        margin-bottom: 0.42em;
    }
    .box-toolbar{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    .box-money{
        font-size: 1em;
        min-height: 1.4em;
        &.box-money-ori{
            color:#898989;
            text-decoration: line-through;
        }
    }
    .box-tool-btn{
        width: 1.857em;
        height: 1.857em;
        @include flex(center,center);
        color: #fff;
        background: $color-primary;
        border-radius: 50%;
        font-size: 1em;
        flex-shrink: 0;
        margin-left: 7px;
    }

    .box-price-displayer{
        .muki-price-displayer{
            display: block;
            + .muki-price-displayer{
                margin-top: 7px;
            }
        }
    }

    @include mobile-down{
        .box-body{
            padding: 5px;
        }
        .box-countdonw-bar{
            .countdown-gadge {
                .gadge-title{
                    font-size: 1em;
                }
            }
        }
        .muki-price-displayer{
            font-size: calc( 15 / 14 * 1rem);
        }
    }
}


