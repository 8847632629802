
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.common-link-bar{
    text-align: right;
    margin-bottom: 30px;
}

@media screen and (max-height: 811.98px) {
    .ios, .md{
        .section-lg-title{
            margin-bottom: 0.7em;
        }
        .logo-area{
            margin-bottom: 10px;
        }
    }


    .base-input{
        margin-bottom: 10px;
    }

    .common-link-bar{
        margin-bottom: 15px;
    }
}

@include mobile-down{
    .ios, .md{
        .section-lg-title{
            margin-bottom: 0.5em;
        }
    }

    .base-input{
        margin-bottom: 10px;
    }

    .common-link-bar{
        margin-bottom: 15px;
    }
}



