
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.page{
    &:before{
        content: '';
        box-shadow: none !important;
    }
}


